/*
 * Tous ce qui concerne la gestion des wishlist (fiche produit et page wishlist)
 * product_right.php, wishlist_index.php
 */

var button_add_to_wishlist_initial_click_event;

/**
 * Dans la fiche produit. Le bouton ajouter à la wishlist. (product_right.php)
 */
function addToWishlist() {

    var btn = $(this);
    var idElt = btn.data('idelt');
    var wishlistProductId = btn.data('wishlistproductid');
    var productId = btn.data('productid');
    var colorId = $('#couleurProd_' + idElt).length == 1 ? $('#couleurProd_' + idElt).val() : $('#couleurProd').val();
    var sizeId = $('#tailleProd_' + idElt).length == 1 ? $('#tailleProd_' + idElt).val() : $('#tailleProd').val();
    var need_size_id = 'false';
    var currentPage = $('#page').val();
    var wishlist = [];
    var wishtTop = $('#wishlist_top');
    var shadMenu = $('#shad_menu');

    var datas = {
        product_id: productId,
        color_id: colorId,
        size_id: sizeId,
        need_color_id: 'true',
        need_size_id: need_size_id
    };
    if (!btn.hasClass('existToWishlistButton')) {
        $.ajax({
            url: path_relative_root + create_link("ajax_add_to_wishlist"),
            type: 'post',
            data: datas
        })
            .done(function (resp) {
                var $alertboxLogin = $("#abox");
                var $alertboxBtns = $("#abox .wrap_btn_abox");
                $alertboxLogin.addClass('box_wishlist');

                if (resp.error) {

                    var message = resp.result.error_message ? resp.result.error_message : resp.message;
                    if (resp.message === "login_required") {
                        var wishlistSubmit = $('.form_submit_wishlist', $alertboxLogin);
                        if (wishlistSubmit.length === 0) {
                            $alertboxBtns
                                .append($(
                                    '<div class="form_submit form_submit_wishlist">' +
                                    '<button class="button primary"  href="javascript:void(0)" onclick="wishlistRedirectLogin(\'' + productId + '\', \'' + currentPage + '\');">' +
                                    '<span>' +
                                    resp.result.btn_connexion +
                                    '</span>' +
                                    '</button>' +
                                    '</div>')
                                );
                        } else {
                            wishlistSubmit.css('display', 'flex');
                        }
                        alert('<div class="box_txt_wishlist">' + resp.result.error_message + '</div>');
                    } else if (resp.message != "error") {
                        alert('<div class="box_txt_wishlist">' + resp.message + '</div>');
                    } else {
                        alert(Translator.translate(message));
                    }
                } else {
                    btn.addClass("existToWishlistButton");
                    wishtTop.addClass('hasItem');
                    btn.data('wishlistproductid', resp.result.wishlist_product_id);
                    btn.attr('data-wishlistproductid', resp.result.wishlist_product_id);
                    if (typeof (wishlist) == 'undefined') {
                        wishlist = new Array();
                    }
                    wishlist.push(resp.result.wishlist_row_array);
                    addToWishlistUpdateHeader(resp.result.wishlist_size);
                    $(".block_top_links #wishlist_top").addClass('hover');
                    shadMenu.addClass('actif');

                    setTimeout(function() {
                        $(".block_top_links #wishlist_top").removeClass('hover');
                        shadMenu.removeClass('actif');
                    }, 2000);
                }
            });
    } else {
        datas.remove = true;

        $.ajax({
            url: path_relative_root + create_link("ajax_remove_from_wishlist"),
            type: 'post',
            data: datas
        })
            .done(function (resp) {
                if (!resp.error) {
                    var response_html = ajax_file(path_relative_root + 'ajax_show_wishlist.php');

                    btn.removeClass("existToWishlistButton");
                    btn.data('wishlistproductid', '');
                    btn.attr('data-wishlistproductid', '');
                    $("#wishlist_top").html(response_html);
                    addToWishlistUpdateHeader(resp.result.wishlistLength);
                }
            });
    }
}

/**
 * Permet d'ajour un produit à la wishlist via le rayon (ou la recherche)
 * Utilisation : app/_components/product/achat_express.php/achat_express_search.php
 */
function addToWishlistRay() {

    // On utilise le même que dans la fiche produit car cela fait la même chose
    // mais on donne un nom différent au cas où
    addToWishlist.call(this);

    // Quand on ajoute un produit a la wishlist, depuis le bloc produits associé (Page panier: "Vous aimerez aussi")
    if ($('body.cart.step_1 .wrapperCartAssociations').length) {
        setTimeout(() => {
            location.reload();
        }, 500);
    }
}

/**
 *
 * @param productId
 * @param itm_rr_id
 * @returns {boolean}
 */
function checkProductInWishlist(productId, itm_rr_id) {
    var prod_id = itm_rr_id ? productId + itm_rr_id : productId;
    if (prod_id == undefined || !prod_id) {
        if ($("produit_id").length) {
            prod_id = $("produit_id").val();
        } else if ($("#addToWishlistButton").length && $("#addToWishlistButton").data('productid') != undefined) {
            prod_id = $("#addToWishlistButton").data('productid');
        }
    }

    var colorId = 0;
    var sizeId = 0;
    var colorId = $("#couleurProd_" + prod_id).length ? $("#couleurProd_" + prod_id).val() : $("#couleurProd").val();
    var sizeId = $("#tailleProd").val();
    if (colorId == undefined || colorId == '') {
        colorId = 0;
    }
    if (sizeId == undefined || sizeId == '') {
        sizeId = 0;
    }

    if (typeof prod_id != "undefined" && typeof colorId != "undefined" && typeof sizeId != "undefined") {
        $.ajax({
            type: 'get',
            url: path_relative_root + 'get_user_wishlist.php',
            data: {'product_id': prod_id, 'size_id': sizeId, 'color_id': colorId},
            success: function (response) {
                var userWishlist;

                try {
                    userWishlist = JSON.parse(response);
                } catch (e) {
                }

                if (userWishlist.length) {
                    changeBtnAddWishlist(true);
                    return true;
                } else {
                    changeBtnAddWishlist(false);
                    return false;
                }
            }
        });
    } else {
        changeBtnAddWishlist(false);
        return false;
    }
}

/*
 * Permet de changer l'action et le visuel du bouton d'ajout à la wishlist
 */
function changeBtnAddWishlist(in_wishlist) {

    if (in_wishlist) {
        $("#addToWishlistButton").attr("onclick", "");
        $("#addToWishlistButton").attr("href", path_relative_root + create_link("wishlist"));
        $("span", "#addToWishlistButton").html(Translator.translate("exist_to_wishlist_button_label"));
        $("#addToWishlistButton").removeClass("addToWishlistButton").addClass("existToWishlistButton");
    } else {
        $("#addToWishlistButton").attr("onclick", button_add_to_wishlist_initial_click_event);
        $("#addToWishlistButton").attr("href", "javascript:;");
        $("span", "#addToWishlistButton").html(Translator.translate("wishlist"));
        $("#addToWishlistButton").removeClass("existToWishlistButton").addClass("addToWishlistButton");
    }
}

function wishlistRedirectLogin(div_id, currentPage) {
    $.get(path_relative_root + "ajax_add_to_wishlist.php?login_required=1&div_id=" + div_id + "&currentPage=" + currentPage, function () {
        document.location.href = path_relative_root + create_link('connexion_login') + "?from=front&div_id=" + div_id;
    });
}

function addToWishlistUpdateHeader(nr) {

    if (!document.body.classList.contains('mobile')) {
        if (nr === 0) {
            $("#wishlist_top").removeClass('hasItem');

        } else {

            $("#wishlist_top .nbProd").html(nr);
        }

        var response_html = ajax_file(path_relative_root + 'ajax_show_wishlist.php');
        $("#wishlist_top").html(response_html);
    }
}

function scanForChanges() {
    var totalPrice = 0;
    selectedProducts = []; // global
    selectedProductsRef = []; // global
    wishlistProductData = [];
    selectedProductsObj = {};

    $(".checkbox_select_product").each(function () {
        var element = $(this);
        var id = element.attr("data-id");

        if (element.is(':checked')) {
            var price = parseFloat(element.attr('data-price'));
            var ref = element.attr('data-ref');

            if (price) {
                totalPrice += price;
            }

            wishlistProductData.push({
                productId: element.attr("data-produit-id"),
                colorId: element.attr("data-color-id"),
                sizeId: element.attr("data-size-id"),
            });

            selectedProductsObj.selectedProducts = JSON.stringify(wishlistProductData);
            selectedProducts.push(element.attr("data-id"));
            selectedProductsRef.push(ref);
            if (element.attr("pointscadeau")) {
                totalPoints += parseInt(element.attr("pointscadeau"));
            }
        }
    });
    var txt = "",
        text = "";
    var priceSpan = $("#total_price");
    switch (selectedProducts.length) {
        case 0:
            txt = $texts[0] + ' :';
            priceSpan.html(formatPrice('0'));
            break;
        case 1:
            text = $texts[1];
        default:
            if (text === '')
                text = $texts[2];

            priceSpan.html(formatPrice(parseFloat(totalPrice.toFixed(2))));
            txt = '<span class="wishlist_count">' + selectedProducts.length + '</span> ' + text + ' :';
    }

    $(".txt", "#nrArticlesSelected").html(txt);
    $("#wishlist_top .nbProd").html($('.product_ctn').length);
}

function addProductToCart(product_id, color_id, size_id) {
    $.post(path_relative_root + create_link('ajax_wishlist_add_product_to_cart'),
        {
            product_id: product_id,
            color_id: color_id,
            size_id: size_id
        }, function (resp) {
            if (resp === "ok") {
                document.location.href = path_relative_root + create_link('order_basket');
            } else {
                alert(Translator.translate('error_add_basket'));
            }
        });
}

function addCustomizedProductToCart(pc_id) {
    var errors = [];

    //get data
    var customizationData = {};
    $.ajax({
        url: path_relative_root + create_link('ajax_configurator_get_configuration'),
        type: 'post',
        async: false,
        data: {pc_id: pc_id},
        dataType: 'json',
        success: function (response) {
            if (response.success) {
                customizationData = response.json;
            }
        }
    });

    // add main product to basket
    if (customizationData.steps.length) {
        //send main product
        customizationData.product.configurator_product = false;
        customizationData.product.reinit_basket_virtuel_parent_id = true;
        customizationData.product.set_session_basket_as_parent = true;
        var response = sendProductToBasket(customizationData.product);
        // if main product added, add other products
        if (response.error !== undefined && response.error !== 0) {
            if (response.error.msg !== '' && response.error.msg !== undefined) {
                alert(Translator.translate('error_insert_basket') + '<br>' + (errors.join('<br>')));
            }
        } else {
            $.each(customizationData.steps, function (index, customizationData) {
                $.each(customizationData.customization, function (indexProduct, product) {
                    product.id = product.product_id;
                    product.configurator_product = true;
                    var response = sendProductToBasket(product);
                    // On regarde si il y a eu une erreur dans l'ajout (cas plus de stock)
                    if (response.error !== undefined) {
                        if (response.error.msg !== '' && response.error.msg !== undefined) {
                            errors.push(response.error.msg);
                        }
                    }
                });
            });

            if (errors.length) {
                alert(Translator.translate('error_insert_basket') + '<br>' + (errors.join('<br>')));
            } else {
                //update produit_configurator_data
                $.ajax({
                    url: path_relative_root + create_link('ajax_configurator_update_virtual_basket_id'),
                    type: 'post',
                    async: false,
                    data: {
                        pc_id: pc_id
                    },
                    dataType: 'json',
                    success: function (response) {
                        if (response.success) {
                            document.location.href = path_relative_root + create_link('order_basket');
                        } else {
                            alert(Translator.translate('error_add_basket'));
                        }
                    }
                });
            }
        }
    }
}

function sendProductToBasket(product) {
    var response = {};
    $.ajax({
        url: path_relative_root + "ajax_upd_panier.php",
        type: "post",
        dataType: 'json',
        async: false,
        data: {
            configurator: true,
            idprod: product.id,
            idcouleur: product.color_id,
            idtaille: product.size_id,
            panierid: 0,
            basket_virtuel_parent_id: product.basket_virtuel_parent_id,
            qte: 1,
            configurator_product: product.configurator_product,
            dataTypeReturn: 'json',
            reinit_basket_virtuel_parent_id: product.reinit_basket_virtuel_parent_id != undefined ? product.reinit_basket_virtuel_parent_id : false,
            set_session_basket_as_parent: product.set_session_basket_as_parent != undefined ? product.set_session_basket_as_parent : false,
        },
        success: function (res) {
            response = res;
        }
    });
    return response;
}

/*
 *   Reset le bloc produits associé (Page panier: "Vous aimerez aussi").
 *   L'event custom proviens de la function removeFromWish() de _app/src/js/dragdrop-wishlist.js
 */
window.addEventListener("removeFromWish_success", function(event) {
    if ($('body.cart.step_1 .wrapperCartAssociations').length) {
        location.reload();
    }
});